import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/ruoyi'
const api = {
  list: '/system/user/list',
  profile: '/system/user/profile',
  updatePwd: '/system/user/profile/updatePwd',
  resetPwd: '/system/user/resetPwd'
}

export default api

// export function getUserList (parameter) {
//   return request({
//     url: api.list,
//     method: 'get',
//     params: parameter
//   })
// }

export function profile (parameter) {
  return request({
    url: api.profile,
    method: 'put',
    data: parameter
  })
}

export function updatePwd (parameter) {
  return request({
    url: api.updatePwd,
    method: 'put',
    params: parameter
  })
}

export function resetPwd (parameter) {
  return request({
    url: api.resetPwd,
    method: 'put',
    data: parameter
  })
}

// 查询用户列表
export function listUser (query) {
  return request({
    url: '/system/user/list',
    method: 'get',
    params: query
  })
}

// 查询用户详细
export function getUser (userId) {
  return request({
    url: '/system/user/' + praseStrEmpty(userId),
    method: 'get'
  })
}

// 新增用户
export function addUser (data) {
  return request({
    url: '/system/user',
    method: 'post',
    data: data
  })
}

// 修改用户
export function updateUser (data) {
  return request({
    url: '/system/user',
    method: 'put',
    data: data
  })
}

// 删除用户
export function delUser (userId) {
  return request({
    url: '/system/user/' + userId,
    method: 'delete'
  })
}

// 用户密码重置
export function resetUserPwd (userId, password) {
  const data = {
    userId,
    password
  }
  return request({
    url: '/system/user/resetPwd',
    method: 'put',
    data: data
  })
}

// 用户状态修改
export function changeUserStatus (userId, status) {
  const data = {
    userId,
    status
  }
  return request({
    url: '/system/user/changeStatus',
    method: 'put',
    data: data
  })
}

// 查询用户个人信息
export function getUserProfile () {
  return request({
    url: '/system/user/profile',
    method: 'get'
  })
}

// 修改用户个人信息
export function updateUserProfile (data) {
  return request({
    url: '/system/user/profile',
    method: 'put',
    data: data
  })
}

// 用户密码重置
export function updateUserPwd (oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword
  }
  return request({
    url: '/system/user/profile/updatePwd',
    method: 'put',
    params: data
  })
}

// 用户头像上传
export function uploadAvatar (data) {
  return request({
    url: '/system/user/profile/avatar',
    method: 'post',
    params: data
  })
}
  
/*
根据角色获取用户列表
参数roleName
参数roleKey
*/
export function getUserListByRole (params) {
  return request({
    
    url: `/system/user/getUserListByRoleName?roleName=${params.roleName}`,
    method: 'get',
  })
}
