<template>
    <page-header-wrapper>
        <template v-slot:content>
            <div class="page-header-content">
                <a-card :bordered="false" class="content">
                    <div class="table-page-search-wrapper">
                        <a-form layout="inline" ref="queryForm">
                            <a-row :gutter="48">
                                <a-col :md="16" :sm="48" style="display:blank">
                                    <a-form-item label="管理对象">
                                        <a-button @click="e=>{$router.push('/staff/student')}">学生</a-button>&nbsp;
                                        <a-button @click="e=>{$router.push('/staff/teacher')}">教师</a-button>&nbsp;
                                        <a-button type="primary">教管</a-button>&nbsp;
                                        <a-button @click="e=>{$router.push('/staff/principal')}">院长</a-button>
                                    </a-form-item>
                                </a-col>
                            </a-row>
                            <a-row :gutter="48">
                                <a-col :md="6" :sm="18" style="display:blank">
                                    <a-form-item label="学校">
                                        <a-input placeholder="请输入" v-model="queryParams.type" style="display:none"/>
                                        <a-select ref="sel"
                                            v-hasPermi="['organization:university:list']"
                                            v-model="queryParams.universityId"
                                            @change="
                                                (e) => {
                                                    //getTeacherList();
                                                }
                                            "
                                        >
                                            <a-select-option
                                                v-for="item in universityList"
                                                :value="item.universityId"
                                                :key="item.universityId"
                                                >{{ item.universityName }}</a-select-option
                                            >
                                        </a-select>
                                    </a-form-item>
                                </a-col>
                                <a-col :md="6" :sm="18">
                                    <a-form-item label="姓名">
                                        <a-input placeholder="请输入" v-model="queryParams.teacherName" />
                                    </a-form-item>
                                </a-col>
                                <a-col :md="6" :sm="18">
                                    <span class="table-page-search-submitButtons">
                                        <a-button @click="handleQuery" type="primary">查询</a-button>
                                        <a-button @click="resetQuery" style="margin-left: 8px">重置</a-button>
                                    </span>
                                </a-col>
                            </a-row>
                        </a-form>
                    </div>
                    <div class="table-page-operator-wrapper">
                        <a-button
                            @click="handleAddTeacherClick"
                            type="primary"
                            v-hasPermi="['staff:teacher:add']"
                            ghost
                            >新增</a-button
                        >
                    </div>
                    <!-- 表格 -->
                    <a-table
                        ref="table"
                        :columns="columns"
                        :loading="tableLoading"
                        :data-source="teacherList"
                        row-key="teacherId"
                        :pagination="false"
                    >
                        <!-- 插槽指向状态 -->
                        <span slot="logo" slot-scope="text">
                            <img v-if="text" :src="config.staticResourceDomain + text" alt="avatar" class="avatar" />
                        </span>
                        <span slot="sex" slot-scope="text">
                            {{text=='0'?'女':'男'}}
                        </span>
                        <span slot="status" slot-scope="text">
                            <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
                        </span>
                        <!-- 更多选择 -->
                        <span slot="action" slot-scope="text, record">
                            <a
                                 @click="handleAccountLinkClick(record)"
                                v-hasPermi="['sys:user:query']"
                                >{{record.userId?'修改账号':'创建账号1'}}</a
                            >
                            <a-divider type="vertical" />
                            <a
                                @click="$refs.createModal.show(record, true)"
                                v-hasPermi="['organization:class:query']"
                                >详细</a
                            >
                            <a-divider type="vertical" />
                            <a
                                @click="$refs.createModal.show(record, false)"
                                v-hasPermi="['organization:class:edit']"
                                >编辑</a
                            >
                            <a-divider type="vertical" />
                            <a
                                href="javascript:;"
                                v-hasPermi="['organization:class:remove']"
                                @click="handleDelete(record)"
                                >删除</a
                            >
                            <a-dropdown v-if="false">
                                <a class="ant-dropdown-link"> 更多 <a-icon type="down" /> </a>
                                <a-menu slot="overlay">
                                    <a-menu-item>
                                        <a
                                            href="javascript:;"
                                            v-hasPermi="['organization:class:remove']"
                                            @click="handleDelete(record)"
                                            >删除</a
                                        >
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </span>
                    </a-table>
                    <!-- 底部分页按钮 -->
                    <a-pagination
                        class="ant-table-pagination"
                        v-model="current"
                        :page-size-options="pageSizeOptions"
                        :total="total"
                        show-size-changer
                        :page-size="pageSize"
                        @showSizeChange="onShowSizeChange"
                        @change="currentPageChange"
                    >
                        <template slot="buildOptionText" slot-scope="props">
                            <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
                            <span v-if="props.value === '50'">全部</span>
                        </template>
                    </a-pagination>
                    <!-- 创建/编辑部门,单独封装了组件 -->
                    <create-form ref="createModal" @handle-success="handleOk" />
                    <reg-form ref="regModal" @handle-success="handleOk" />
                </a-card>
            </div>
        </template>
    </page-header-wrapper>
</template>

<script>
// import { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import { listUniversity } from '@/api/rongyi/organization/university'
import { listTeacher } from '@/api/rongyi/staff/teacher'
import { getUser } from '@/api/system/user'
import { isEmptyStr } from '@/utils/util'
import CreateForm from './modules/CreateForm'
import RegForm from './modules/RegForm'
import rryConfig from '@/../config/rongyiYun.config'
const statusMap = {
    0: {
        status: 'processing',
        text: '正常',
    },
    1: {
        status: 'default',
        text: '停用',
    },
}
export default {
    name: 'class',
    components: {
        CreateForm,
        RegForm,
    },
    data() {
        return {
            config: rryConfig,
            universityList:[],
            teacherList:[],
            // 分页数据(默认第一页):
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            current: 1,
            pageSize: 10,
            total: 0,
            statusMap: [],
            tableLoading: false,
            // 表格树数据
            // 部门树选项
            deptOptions: [],
            // 状态数据字典
            statusOptions: [],
            // 查询参数
            queryParams: {
                // universityId:undefined,
                pageNum: 1,
                pageSize: 10,
                type: 3

            },
            // 表头
            columns: [
                {
                    title: '姓名',
                    dataIndex: 'teacherName',
                },
                {
                    title: '性别',
                    dataIndex: 'sex',
                    scopedSlots: { customRender: 'sex' }
                },
                {
                    title: '手机',
                    dataIndex: 'tephone',
                },
                {
                    title: '邮箱',
                    dataIndex: 'email',
                },
                {
                    title: '操作',
                    width: '150',
                    dataIndex: 'action',
                    scopedSlots: { customRender: 'action' },
                },
            ],
            // 表格多选
            selectedRowKeys: [],
            selectedRows: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRowKeys = selectedRowKeys
                    this.selectedRows = selectedRows
                },
                onSelect: (record, selected, selectedRows) => {},
                onSelectAll: (selected, selectedRows, changeRows) => {},
            },
        }
    },
    // 状态过滤
    filters: {
        statusFilter(type) {
            return statusMap[type].text
        },
        statusTypeFilter(type) {
            return statusMap[type].status
        },
    },
    created() {
        // this.queryParams.universityId = this.$route.query.uid || this.$store.state.user.universityId || '00000000000000000000000000000000';
        this.getUniversityList().then((res)=>{this.getTeacherList()})
        // this.getTeacherList()
        // this.getDicts('sys_normal_disable').then(response => {
        //   this.statusOptions = response.data
        // })
        // console.log(this.$store.state);
    },
    watch: {
        '$route' (to, from) {
            if (this.$route.query.uid) {
                this.queryParams.universityId = this.$route.query.uid;
                this.getTeacherList();
            }
        }
    },
    methods: {
        getUniversityList() {
            const p = new Promise((resolve,reject)=>{
                listUniversity().then((response) => {
                    if(response.code == 200){
                        this.universityList = response.rows
                        
                console.log(this.universityList);
                        resolve(response)
                    }else{
                        reject(response.msg)
                    }
                })
            });
            return p;
        },
        getTeacherList() {
            console.log(this.$refs.sel);
            const p = new Promise((resolve,reject)=>{
                listTeacher(this.queryParams).then((response) => {
                    if(response.code == 200){
                        this.teacherList = response.rows
                        this.total = response.total
                        
                        resolve(response)
                    }else{
                        reject(response.msg)
                    }
                })
            });
            return p;
        },
        /** 转换部门数据结构 */
        // normalizer(node) {
        //     if (node.children && !node.children.length) {
        //         delete node.children
        //     }
        //     return {
        //         id: node.deptId,
        //         label: node.deptName,
        //         children: node.children,
        //     }
        // },
        // 字典状态字典翻译
        statusFormat(row, column) {
            return this.selectDictLabel(this.statusOptions, row.status)
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getTeacherList()
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.queryParams.teacherName = undefined
            this.queryParams.universityId = undefined
            this.handleQuery()
        },
        handleAccountLinkClick(record){
            let account = {}
            if(record.userId){
                getUser(record.userId).then(res=>{
                    if(res.code ==200){
                        account = res.data;
                        this.$refs.regModal.show(account, false, record);
                    }
                })
            }else{
                //新增
                account.nickName = record.teacherName
                account.phonenumber = record.tephone
                account.email = record.email
                account.password = '123456'
                account.sex = record.sex
                account.status = record.status
                account.hostType = 1
                account.hostId = record.universityId
                account.staffType = 3
                account.staffId = record.teacherId
                account.postIds = []
                account.roleIds = [7]
                // account.roles= [{
                //     roleId: 8,
                //     roleKey: "sdt",
                //     roleName: "学生",
                //     roleSort: "8",

                // }]
                this.$refs.regModal.show(account, false, record);
            }
        },
        handleAddTeacherClick(){
            // if(isEmptyStr(this.queryParams.universityId)){
            //     this.$message.warning("请先选择学校")
            //     return;
            // }
            this.$refs.createModal.show(null, false,this.queryParams.universityId);
        },
        // 新增/修改框事件
        handleOk() {
            this.getTeacherList()
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const that = this
            this.$confirm({
                title: '警告',
                content: `真的要删除 名为${row.teacherName}的数据项吗?`,
                okText: '删除',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    delTeacher(row.specializeId).then((response) => {
                        if (response.code === 200) {
                            that.$message.success('删除成功!')
                            that.getList()
                        } else {
                            that.$message.error(response.msg)
                        }
                    })
                },
            })
        },
        /** pageSize 变化的回调 */
        onShowSizeChange(current, pageSize) {
            this.current = current
            this.pageSize = pageSize
            this.queryParams.pageSize = pageSize
            this.queryParams.pageNum = current
            this.getTeacherList()
        },
        /** 页码改变的回调 */
        currentPageChange(current, pageSize) {
            this.current = current
            this.pageSize = pageSize
            this.queryParams.pageSize = pageSize
            this.queryParams.pageNum = current
            this.getTeacherList()
        },
    },
}
</script>
<style scoped>
.avatar {
    width: 32px;
    height: 32px;
}
</style>