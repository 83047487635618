<template>
    <a-modal
        ref="createModal"
        :title="readOnly ? '详情' : form.studentId ? '学生编辑' : '新增操作'"
        :width="640"
        :visible="visible"
        @cancel="close"
        @ok="confirm"
        :confirmLoading="confirmLoading"
        :okButtonProps="{
            props: {
                disabled: readOnly ? true : false,
            },
        }"
    >
        <a-spin :spinning="loading">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row>
                    <a-col :span="12">
                        <a-form-model-item label="学校" prop="universityId">
                            <a-select :disabled="readOnly"
                                v-model="form.universityId"
                                v-hasPermi="['organization:university:list']"
                            >
                                <a-select-option
                                    v-for="item in universityList"
                                    :value="item.universityId"
                                    :key="item.universityId"
                                    >{{ item.universityName }}</a-select-option
                                >
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="姓名" prop="teacherName">
                            <a-input v-model="form.teacherName" placeholder="姓名" :disabled="readOnly" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="性别" prop="sex">
                            <a-radio-group v-model="form.sex">
                                <a-radio
                                v-for="dict in sexOptions"
                                :key="dict.dictValue"
                                :value="dict.dictValue">
                                {{ dict.dictLabel }}
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="出生日期" prop="birthDate">
                            <a-date-picker
                                v-model="form.birthDate"
                                format="YYYY-MM-DD"
                                :disabled="readOnly"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="政治面貌" prop="politicalFace">
                            <a-select v-model="form.politicalFace" placeholder="选择政治面貌" :disabled="readOnly">
                                <a-select-option value="13">群众</a-select-option>
                                <a-select-option value="1">中共党员</a-select-option>
                                <a-select-option value="2">中共预备党员</a-select-option>
                                <a-select-option value="3">共青团员</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="家庭电话" prop="phone">
                            <a-input
                                v-model="form.phone"
                                placeholder="请输入家庭电话"
                                :disabled="readOnly"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="手机" prop="tephone">
                            <a-input
                                v-model="form.tephone"
                                placeholder="请输入手机号码"
                                :disabled="readOnly"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="邮箱" prop="email">
                            <a-input
                                v-model="form.email"
                                placeholder="请输入邮箱"
                                :disabled="readOnly"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="家庭住址" prop="address">
                            <a-input
                                v-model="form.address"
                                placeholder="请输入家庭住址"
                                :disabled="readOnly"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
        </a-spin>
    </a-modal>
</template>

<script>
import { listUniversity } from '@/api/rongyi/organization/university'
import { addTeacher, updateTeacher } from '@/api/rongyi/staff/teacher'
import rryConfig from '@/../config/rongyiYun.config'
import moment from 'moment'
// 表单字段
export default {
    data() {
        return {
            parentId: undefined,
            config: rryConfig,
            logoUrl: '',
            token: '',
            temp: {},
            confirmLoading: false,
            readOnly: false,
            visible: false,
            loading: false,
            // form: this.$form.createForm(this),
            // value: undefined,
            labelCol: {
                xs: { span: 12 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
            // 表单属性:需要设定默认值的字段必须先定义(Vue的响应式property必须在初始化的时候声明)
            form: {
                universityId:undefined,
                birthDate:undefined,
                sex:undefined,
                politicalFace:undefined,
            },
            // 表单校验
            rules: {
                universityId: [{ required: true, message: '请选择学校', trigger: 'blur' }],
                email: [
                    {
                        required: true,
                        type: 'email',
                        message: '请输入正确的邮箱地址',
                        trigger: ['blur', 'change'],
                    },
                ],
                linkmanPhone: [
                    {
                        required: true,
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: '请输入正确的手机号码',
                        trigger: 'blur',
                    },
                ],
                postcode: [
                    {
                        required: true,
                        pattern: /[1-9][0-9]{5}/,
                        message: '请输入正确的邮编',
                        trigger: 'blur',
                    },
                ],
                // address: [{ required: true, message: '地址不能为空', trigger: 'blur' }],
                linkman: [{ required: true, message: '联系人不能为空', trigger: 'blur' }],
            },
            universityList:[],
            sexOptions:[]
        }
    },
    created() {
        this.getDicts('sys_user_sex').then((response) => {
            this.sexOptions = response.data
        })
        this.getUnviersityList()
    },
    methods: {
        // 由于要用传进来的值做判断,将显示和隐藏放在内部做处理
        
        getUnviersityList() {
            const p = new Promise((resolve,reject)=>{
                let self = this
                listUniversity().then((response) => {
                    if(response.code == 200){
                        self.universityList = response.rows
                        resolve(response.rows)
                    }else{
                        reject(response.msg)
                    }
                })
            });
            return p;
        },
        show(data, readOnly) {
            // this.form.universityId = universityId
            // this.form.departmentId = departmentId
            // this.form.gradeId = gradeId
            // this.form.specializeId = specializeId
            // this.form.specializeId = specializeId
            // this.form.classId = classId
            console.log('data');
            console.log(data);
            if (data) {
                // 修改行为
                this.form = Object.assign({}, data) || {}
            } else {
                // 新增行为
                this.resetForm()
            }
            this.readOnly = typeof readOnly !== 'undefined' ? readOnly === true : false
            this.visible = true
        },
        // 关闭模态框
        close() {
            this.visible = false
            this.resetForm()
        },
        confirm() {
            console.log('form')
            console.log(this.form)
            this.confirmLoading = true
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    console.log('valid success')
                    console.log(this.form)
                    // 进行新增行为:
                    if (!this.isEmptyStr(this.form.teacherId)) {
                        // 刷新表格
                        updateTeacher(this.form).then((response) => {
                            if (response.code === 200) {
                                this.$message.success('修改成功')
                                // 关闭本组件
                                this.visible = false
                                // 调用外部刷新列表方法
                                this.$emit('handle-success')
                                // 刷新表单
                                this.resetForm()
                                this.confirmLoading = false
                            } else {
                                this.$message.error(response.msg)
                                this.confirmLoading = false
                            }
                        })
                    } else {
                        console.log("new student form");console.log(this.form);
                        // 新增
                        this.form.status = 1
                        this.form.type = 3
                        addTeacher(this.form).then((response) => {
                            if (response.code === 200) {
                                this.$message.success('新增成功')
                                // 关闭本组件
                                this.visible = false
                                // 调用外部刷新列表方法
                                this.$emit('handle-success')
                                // 刷新表单
                                this.resetForm()
                                this.confirmLoading = false
                            } else {
                                this.$message.error(response.msg)
                                this.confirmLoading = false
                            }
                        })
                    }
                } else {
                    console.log('valid false')
                    console.log(this.form)
                    return (this.confirmLoading = false)
                }
            })
        },
        // 表单重置
        resetForm() {
            for(var key in this.form){
                this.form[key] = undefined;
            }
            //默认值
            this.form.politicalFace = '13'
            this.form.universityId = this.$store.state.user.host_id || '00000000000000000000000000000000'
            this.form.sex='0'
            this.form.birthDate = moment(new Date('1985/1/1'))
        },
        filterStrArrary(list) {
            for (let index = 0; index < list.length; index++) {
                if (list[index].children === '') {
                    list[index].children = []
                }
                if (list[index].children) {
                    this.filterStrArrary(list[index].children)
                }
            }
        },
        //操作身份照片上传
        handleChange(info) {
            const self = this
            if (info.file.status === 'uploading') {
                this.loading = true
                return
            }
            if (info.file.status === 'done') {
                // Get this url from response in real world.
                console.log(info.file.response.data.name)
                this.form.logo = info.file.response.data.url
                // this.logoUrl = self.config.staticResourceDomain + info.file.response.data.url
                this.loading = false
            }
        },
        beforeUpload(file) {
            console.log('beforeUpload')
            //获取token
            const tk = localStorage.getItem('token')
            if (tk) {
                this.token = 'Bearer ' + tk
            }

            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
            if (!isJpgOrPng) {
                this.$message.error('只能上传jpeg、png格式的图片文件!')
            }
            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isLt2M) {
                this.$message.error('图像大小不能超过 2MB!')
            }
            return isJpgOrPng && isLt2M
        },
    },
}
</script>
<style scoped>
.avatar {
    width: 128px;
    height: 128px;
}
.avatar-uploader {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
}
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>